import escapeText from 'lib/escapeText'
import XRegExp from 'xregexp'

const regex = {
  email: /^[^@]+@[^@]+$/,
  objectNestingSeparator: /\.|\[/,
  newlinePreserve: /(\r\n|\r|\n)/,
  notNewLine: /([^\n])/,
  removeTrailingZeroes: /[.,](00|0)($|[^0-9])/,
  // accepts year as 4 characters - adapted from https://stackoverflow.com/questions/5465375/javascript-date-regex-dd-mm-yyyy
  dateFormatCheck: /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/]\d{4}$/,

  // URLs to be used with the `react-player` library https://github.com/cookpete/react-player/blob/master/src/patterns.js#L3-L4
  reactPlayer: {
    youtube:
      /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\/|watch\?v=|watch\?.+&v=))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//,
    soundcloud: /(?:soundcloud\.com|snd\.sc)\/[^.]+$/,
    mixcloud: /mixcloud\.com\/([^/]+\/[^/]+)/,
  },

  // Matches URLs that have the below structures
  // Starts http:// or https:// or www.
  // Has structure of x.y/z or x.y?z
  // The regex is from the below article with some changes to make it fit our use case
  // https://tutorial.eyehunts.com/js/url-regex-validation-javascript-example-code/
  url: /(http(s)?:\/\/)|(www\.)[a-zA-Z0-9@:%._+~#=-]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|[a-zA-Z0-9@:%._+~#=-]{2,256}\.[a-z]{2,6}\b(\/|\?)([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
  relativeUrl: /^\/([-a-zA-Z0-9@:%_+.~#?&//=]*)/,

  // to match a string that's not inside an `artist` tag
  // https://stackoverflow.com/a/1667004/12667023
  notInArtistTag: (text) =>
    `(<artist(?:[^<](?!/artist))*</artist>)|(${escapeText(text)})`,

  time: {
    hoursMinutes: /\d\d:\d\d/gm,
  },

  costValidation: XRegExp('^[^\\p{L}]*$'),
}

export default regex
